.backvolor{
    background-color: #FDC45A;
    padding: 3px;
}


.woodenfloorberhampore {
    background-color: #FDC360; /* Green */
    border: none;
    color: #ffffff;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .woodenfllormursidabad {
    background-color: white; 
    color: black; 
    border: 2px solid #FDC360;
  }

  .woodenfloorberhampore:hover {
    background-color: #FDC360;
    color: white;
  }

  .texts {
    text-align: justify;
    text-justify: inter-word;
  }




  .whatsappbutton {
    background-color: #076722; /* Green */
    border: none;
    color: #ffffff;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 150px;
  }

  .rmiwoody{
    height:  420px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/Jzjg4k8v/RMI-WOODY-FINEST-WOOD-FOR-YOUR-INTERIOR-8-00002.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

  }