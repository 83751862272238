.woodenfloorberhamporesss{
    background-color: #01A7E5;

}


.btn-grad16 {
    background-image: linear-gradient(to right, #01DF92 0%, #F05A5B  51%, #FAC55C  100%);
    margin: 0px;
    padding: 7px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 11px;
    display: block;
  }
  
  .btn-grad16:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }